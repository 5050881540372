
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
//import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {
    //Dropdown1,
  },

  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    // const baseColor = getCSSVariableValue("--bs-primary");
    // const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const options = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["55%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: true,
        style: { colors: ["#00A4CCFF", "#fbc531"] },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: [],
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Voleum",
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#00A4CCFF", "#fbc531"],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: true,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "" + val + " $";
          },
        },
      },
      colors: ["#00A4CCFF", "#fbc531", "#A07855FF", "#F95700FF"],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Current",
        data: [44, 55, 57, 56, 61, 58],
      },
      {
        name: "Previous",
        data: [76, 85, 101, 98, 87, 105],
      },
    ];

    return {
      options,
      series,
    };
  },
});
